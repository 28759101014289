import { request } from "@/request/http";

export default {
  getExamPaperList: (data) => {
    return request("GET", `/index.php/backquestionbank/getExamPaperList`, data);
  },

  updateSortExamPaper: (data) => {
    return request("GET", `/index.php/backquestionbank/updateSortExamPaper`, data);
  },

  getExamPaperInfo: (data) => {
    return request("GET", `/index.php/backquestionbank/getExamPaperInfo`, data);
  },

  delExamPaper: (data) => {
    return request("GET", `/index.php/backquestionbank/delExamPaper`, data);
  },

  updateQuestionPaper: (data) => {
    return request("GET", `/index.php/backquestionbank/updateQuestionPaper`, data);
  },

  updateExamPaper: (data) => {
    return request("GET", `/index.php/backquestionbank/updateExamPaper`, data);
  },

  statQuestionBankAccuracyRate: (data) => {
    return request("POST", `/index.php/backquestionbank/statQuestionBankAccuracyRate`, data);
  },

};