import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import API from "@/api/teacher/questionbank/questionBank"
import { randomString,isValid,replaceBefore,isStartWithDafa,countOccurrence,extractBase64Images } from "@/utils/function";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import mammoth from "mammoth";

export default {
    name: "other",
    components: {},
    inject:['reload'],
    data() {
        return {
            // 搜索值
            input: "",
            // 当前页码
            page: 1,
            // 一页数据条数
            pagesize :10,
            // 数据总条数
            dataTotal: 500,
            // loading对象
            loading: "",
            // dialog 显示标识
            dialogVisible: false,
            // 表格数据
            tableData: [],
            // 深拷贝试卷列表表格数据
            tableData2:[],
            // 试卷标题
            title: "",
            // 试卷id
            exam_paper_id: "",
            // 操作标识
            handleSign: "",
            // 弹窗标题
            dialogTitle: "",
            // 上传文件列表
            fileList: [
                // {
                //     name: "food.jpeg",
                //     url:
                //         "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg"
                // }
            ],
            filePath: '',
            // 上传url
            uploadUrl : process.env.VUE_APP_DOMAIN_URL+"/backend/public/index.php/group/uploadExportGroupFile",
            // 统计题库准确率弹窗标识
            statVisiable:false,
            // 筛选时间
            statDate:'',
            exam_title:"",
            file:"",

            togetherList:[],
            selectTogether:[],
            id:0,
            dialogShareVisible:false,
        };
    },
    /**
     * @name: 生命周期 --- 创建
     * @author: camellia
     * @date: 2023-04-24
     */
    created() {
        const self = this;
        self.input = this.$router.currentRoute.query.search;
        self.page = Number(this.$router.currentRoute.query.page);
        self.getData();
    },
    /**
     * @name: 生命周期 --- 挂载
     * @author: camellia
     * @date: 2023-04-24
     */
    mounted()
    {
        var self=this;
        self.rowDrop();
    },
    methods: 
    {
        dialogShareFix()
        {
            const self = this;
            let datas = {
                together_id: self.selectTogether,
                id: self.id
            };
            console.log(datas);
            console.log(self.selectTogether);
            self.axios
                .post("/index.php/backquestionbank/shareQuestionBank",datas)
                .then((result)=>{
                self.$Loading.hide();
                nprogressClose();
                if(result.data.code > 0)
                {
                    self.dialogShareVisible = false;
                }
                else
                {
                    self.$Tips({
                    // 消息提示内容
                    message: result.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 消失时间
                    displayTime: 1500
                    });return;
                }
            })
        },
        handleShare(row)
        {
            const self = this;
            self.dialogShareVisible = true;
            self.id = row.id;
            console.log(row);
        },
        downloadAIImportTemplate()
        {
            const self = this;
            window.open(
                process.env.VUE_APP_DOMAIN_URL +
                "/backend/public/index.php/backquestionbank/downloadAIImportTemplate"
            );
        },
        readWordFile(event) 
        {
            const self = this;
            const file = event.target.files[0];
            self.file = file;
        },

        statHandleOK()
        {
            var self = this;
            let data = {
                dateArray: self.statDate,
                exam_paper_id: self.exam_paper_id,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.statQuestionBankAccuracyRate(data)
                .then((result)=>{
                    self.statDate = '';
                    self.statVisiable = false;
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(()=>{
                    nprogressClose();
                    self.$Loading.hide();
                })
        },

        handleOpenStatDialog(row)
        {
            var self = this;
            self.statVisiable = true;
            self.exam_paper_id = row.id;
        },

        indexMethod(index)
        {
            let currentPage = this.page; // 当前页码
            let pageSize = this.pagesize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },

        markHandleBeforeUpload(file) 
        {
            const self = this;
            var array = file.name.split(".");
            if (array.length < 2) 
            {
                // Message.error("文件错误！");
                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"文件错误！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
            var ext = array[array.length - 1];
            if (ext != "xls" && ext != "xlsx") 
            {
                // Message.error("文件格式为xls，xlsx，请重试！");

                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"文件格式为xls，xlsx，请重试",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) 
            {
                // Message.error("上传头像图片大小不能超过 5MB!");
                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"上传头像图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
        },

        handleExceed(files, fileList) 
        {
            this.$Tips({
                // 消息提示内容
                message:`当前限制选择 1 个文件，本次选择了 ${
                    files.length
                    } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                button:[
                    {
                        type:1,
                        text:'知道了'
                    }
                ]
            })
        },

        beforeRemove(file, fileList) 
        {
            return this.$confirm(`确定移除 ${file.name}？`);
        },

        markHandleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.filePath = res.path;
            }
            else 
            {
                Message.error(res.msg);
            }
        },

        downloadImportTemplate() 
        {
            const self = this;
            self.dialogVisible = false;
            window.open(
                process.env.VUE_APP_DOMAIN_URL +
                "/backend/public/index.php/backquestionbank/downloadImportTemplate"
            );
        },

        exportQuestionBank(row = "")
        {
            const self = this;
            self.dialogVisible = false;
            self.exam_paper_id = row.id;
            var url =
                process.env.VUE_APP_DOMAIN_URL +
                "/backend/public/index.php/backquestionbank/exportPaperExcel?exam_paper_id=" +
                self.exam_paper_id;
            window.open(
                url
            );
        },

        compare(property)
        {
            return function(a,b)
                {
                var value1 = a[property];
                var value2 = b[property];
                return value2 - value1;
                }
        },

        rowDrop() 
        {
            const self = this;
            const tbody = document.querySelector(".el-table__body-wrapper tbody");
          // Sortable.create(tbody);
            const sortable = new self.Sortable(tbody, {
                onEnd: (evt) => { // 监听拖动结束事件
                    let data = {};
                    let sortNo = '';
                    let id = '';
                    // evt.oldIndex——当前行的被拖拽前的顺序;
                    // evt.newIndex——当前行的被拖拽后的顺序;
                if(evt.oldIndex < evt.newIndex)
                {
                    // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果;
                    window.sessionStorage.setItem('loading','false');
                    if(evt.newIndex == self.tableData2.length - 1)
                    {
                        sortNo = self.tableData2[evt.newIndex].sort_no - 1;
                        id = self.tableData2[evt.oldIndex].id;
                        self.tableData2[evt.oldIndex].sort_no = sortNo;
                        // 防止拖拽后从后台获取最新数据页面刷新,影响用户体验,前端处理排序
                        self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                    }
                    else
                    {
                        let sortbefore = self.tableData2[evt.newIndex].sort_no - 0;
                        let sortnext = self.tableData2[evt.newIndex + 1].sort_no - 0;
                        sortNo = (sortbefore+sortnext) / 2;
                        id = self.tableData2[evt.oldIndex].id;
                        self.tableData2[evt.oldIndex].sort_no = sortNo;
                        self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                    }   
                    data = {
                        id: id,
                        sort_no: sortNo,
                    };
                }
                else
                {
                    if(evt.newIndex == 0)
                    {
                            sortNo = self.tableData2[evt.newIndex].sort_no - 0 + 1;
                            id = self.tableData2[evt.oldIndex].id;
                            self.tableData2[evt.oldIndex].sort_no = sortNo;
                            self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                    }
                    else
                    {
                            let sortbefore = self.tableData2[evt.newIndex].sort_no - 0;
                            let sortnext = self.tableData2[evt.newIndex - 1].sort_no - 0;
                            sortNo = (sortbefore+sortnext) / 2;
                            id = self.tableData2[evt.oldIndex].id;
                            self.tableData2[evt.oldIndex].sort_no = sortNo;
                            self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                    }
                        // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果
                        window.sessionStorage.setItem('loading','false');
                        data = {
                                    id: id,
                                    sort_no: sortNo,
                        };
                };
                API.updateSortExamPaper(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            window.sessionStorage.setItem('loading','true')
                        }
                        self.getData();
                    })
            }
            })
        },

        handlePreview(row)
        {
            try {
                let bank_id = row.id;
                // router.push({
                //     path: "/teacher/questionPreview",
                //     query: {
                //         bank_id: bank_id,
                //     }
                // });
                window.open("#/teacher/questionPreview?bank_id=" +
                bank_id + 
                '&hideMenu='+ 1 + 
                '&type=' + 1 +
                '&name='+
                row.title.toString()
                )
            } catch (error) {
                
            }
        },

        addQuestion(row) {
            let bank_id = row.id;
            this.$router.push({
                path: "/teacher/questionBankUpdate",
                query: {
                    bank_id: bank_id,
                    hideMenu:1,
                    sign:'add',
                    name:row.title,
                    type:1
                }
            }).then((res)=>{
                this.reload()
                // console.log(res);
            })
            // window.open("#/teacher/questionBankUpdate?bank_id="+
            // bank_id + 
            // '&hideMenu='+ 1 + 
            // '&sign=add' + 
            // '&name='+
            // row.title)
        },

        editQuestion(row) {
            let bank_id = row.id;
            // console.log(row);
            this.$router.push({
                path: "/teacher/questionList",
                query: {
                    bank_id: bank_id,
                    hideMenu:1,
                    name:row.title,
                    type:1
                }
            }).then((res)=>{
                this.reload()
                // console.log(res);
            })
            // window.open("#/teacher/questionList?bank_id="+bank_id + '&hideMenu='+ 1 + '&name=' + row.title)

        },

        searchContent() {
            const self = this;
            self.page = 1;
            // 查询列表
            self.getData();
            this.$router.push({
                path: "/teacher/questionBank",
                query: {
                    search: self.input
                }
            });
        },

        handleOpen(sign, title = "", index = "", row = "") 
        {
            const self = this;
            self.handleSign = sign;
            self.dialogTitle = title;

            if (sign == "is_delete_category") 
            {
                self.dialogVisible = true;
                self.exam_paper_id = row.id;
            } 
            else if (  sign == "is_add_category" || sign == "is_update_category") 
            {
                self.title = ''
                if (sign == "is_update_category") {
                    self.exam_paper_id = row.id;
                    self.getExamPaperInfo();
                }
                self.dialogVisible = true;
            }
            else if (sign == "importQuestionBank") 
            {
                self.exam_paper_id = row.id;
                self.dialogVisible = true;
            }
            else if (sign == "importQuestionBankAI") 
            {
                console.log(row)
                self.exam_paper_id = row.id;
                self.exam_title = row.title
                self.dialogVisible = true;
            }

        },


        getExamPaperInfo() 
        {
            const self = this;
            let data = {
                exam_paper_id: self.exam_paper_id
            };
            // 开启loading
            // startLoading();
            // 请求数据
            API.getExamPaperInfo(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.title = result.info.title;
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                    })
                    .catch(()=>{
                        // 关闭loading
                        // endLoading(startLoading());
                    });
        },


        dialogFix() 
        {
            const self = this;
            let data = {
                title: self.title,
                exam_paper_id: self.exam_paper_id
            };
            if (self.handleSign == "is_delete_category") 
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                API.delExamPaper(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.exam_paper_id = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            } 
            else if(self.handleSign == "importQuestionBank")
            {
                if(!self.filePath || !self.exam_paper_id)
                {
                    this.$Tips({
                        // 消息提示内容
                        message:"参数不全，请重试",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                }
                let data = {
                    exam_paper_id: self.exam_paper_id,
                    filePath: self.filePath,
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.updateQuestionPaper(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.dialogVisible = false;
                            self.exam_paper_id = '';
                            self.filePath = '';
                            self.fileList = [];
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
            else if(self.handleSign == "importQuestionBankAI")
            {
                if (self.file && self.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") 
                {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const arrayBuffer = e.target.result;
                        mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, {ignoreEmptyNodes: true})
                        .then(result => {
                            self.content = result.value;
                            let base64URLArray = extractBase64Images(self.content);
                            let a = 0;
                            for(let k = 0;k < base64URLArray.length;k++)
                            {
                                let data = {
                                    str:base64URLArray[k]
                                }
                                self.axios
                                .post("/index.php/backquestionbank/uploadBase64",data)
                                .then((result)=>{
                                    console.log(result);
                                    self.content = self.content.replace(base64URLArray[k], result.data.data);
                                    a++;
                                    if(a == base64URLArray.length)
                                    {
                                        let num = countOccurrence(this.content,"【",false);
                                        let array = [];
                                        for(let i = 0;i < num; i++)
                                        {
                                            array.push([]);
                                        }
                                        let strArray = this.content.split("</p>");
                                        // console.log(strArray);
                                        let key = -1;
                                        for(let i = 0;i < strArray.length; i++)
                                        {
                                            let str = strArray[i] ?? "";
                                            if(str == "" || str == null)
                                            {
                                                continue;
                                            }
                                            str = str.replace("<p>","");
                                            str = str.replace("<strong>","");
                                            str = str.replace("</strong>","");
                                            if(countOccurrence(str,"【",false) > 0)
                                            {
                                                key++;
                                            }
                                            if(key < 0)
                                            {
                                                continue;
                                            }
                                            array[key].push(str);
                                        }
                                        let questionList = [];
                                        for(let item = 0;item < array.length;item++)
                                        {
                                            // console.log(array[item])
                                            let questionItem = {
                                                // ===========================  ueditor  使用代码 start   ================
                                                defaultMsg: "", //默认显示的文字
                                                ue1: "", // 不同编辑器必须不同的id
                                                ueState: false, //ue状态
                                                // ===========================  ueditor  使用代码 end      ================
                                                // 试题类型
                                                questionType: "radio",
                                                // 试题分数
                                                questionScore: "1",
                                                // 是否必答
                                                is_must_answer: false,
                                                // 是否显示添加选项
                                                is_add_option: false,
                                                // 正确答案（单选A）
                                                radio_right_key: "A",
                                                // 正确答案（多选[A,B,C]）
                                                checkbox_right_key: ["A"],
                                                // 正确答案（对/错）
                                                judge_right_key: "对",
                                                // 单选/多选 选项列表
                                                optionList: [
                                                    // {
                                                    //     index: "A",
                                                    //     content: ""
                                                    // },
                                                ],
                                                judgeOptionList: [
                                                    {
                                                        index: "对"
                                                    },
                                                    {
                                                        index: "错"
                                                    }
                                                ],
                                                // 主键id
                                                exam_id: "",
                                                // 解析
                                                resolution: {
                                                    defaultMsg: "", //默认显示的文字
                                                    ue1: "", // 不同编辑器必须不同的id
                                                    ueState: false //ue状态
                                                }
                                            };
                                            questionItem.ue1 = randomString(10);
                                            questionItem.resolution.ue1 = randomString(10);
                                            for (let j = 0; j < array[item].length; j++) 
                                            {
                                                // 类型
                                                let index = array[item][0].indexOf("【");
                                                let index2 = array[item][0].indexOf("】");
                                                let type = array[item][0].substring(index+1,index2);
                                                if(type == "单选题")
                                                {
                                                    questionItem.questionType = "radio";
                                                }
                                                else if(type == "多选题")
                                                {
                                                    questionItem.questionType = "checkbox";
                                                }
                                                else 
                                                {
                                                    questionItem.questionType = "judge";
                                                }
                                                // 题干
                                                questionItem.defaultMsg = replaceBefore(array[item][0],"】");

                                                // 单选题/多选题 选项
                                                if(questionItem.questionType == 'radio' || questionItem.questionType == 'checkbox')
                                                {
                                                    if(isValid(array[item][j]))
                                                    {
                                                        let arr = array[item][j].split(".");
                                                        questionItem.optionList.push({
                                                            index: arr[0],
                                                            content: arr[1]
                                                        });
                                                    }
                                                }
                                                if(isStartWithDafa(array[item][j],"答案"))
                                                {
                                                    let answer = array[item][j].replace("答案","").replace(":","").replace("：","");
                                                    if(questionItem.questionType == 'radio')
                                                    {
                                                        questionItem.radio_right_key = answer;
                                                    }
                                                    else if(questionItem.questionType == 'checkbox')
                                                    {
                                                        let arrayArr = answer.split(",");
                                                        if(arrayArr.length <= 1)
                                                        {
                                                            arrayArr = answer.split("，");
                                                        }
                                                        questionItem.checkbox_right_key = arrayArr;
                                                    }
                                                    else 
                                                    {
                                                        questionItem.judge_right_key = answer;
                                                    }
                                                }
                                                if(isStartWithDafa(array[item][j],"试题解析"))
                                                {
                                                    let jiexi = array[item][j].replace("答案","").replace(":","").replace("：","");
                                                    questionItem.resolution.defaultMsg = jiexi;
                                                }                  
                                            }
                                            questionList.push(questionItem);
                                        }
                                        console.log(questionList)
                                        {
                                            self.dialogVisible = false;
                                            console.log(questionList)
                                            let url = "#/teacher/questionBankUpdate?bank_id="+self.exam_paper_id+
                                                    "&name="+self.exam_title+
                                                    "&list=" + JSON.stringify(questionList)+
                                                    "&hideMenu=1"+
                                                    "&sign=import"+
                                                    "&type=1";
                                            window.open(
                                                url
                                            );
                                        }
                                    }
                                })
                            }//*/
                            console.log(base64URLArray);//*/
                        })
                        .catch(err => console.error(err));
                    };
                    reader.readAsArrayBuffer(self.file);
                } 
                else 
                {
                    alert("请选择一个Word文件");
                }
            }
            else
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.updateExamPaper(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.title = "";
                            self.exam_paper_id = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
        },

        handleCurrentChange(val) 
        {
            const self = this;
            self.page = val;
            self.getData();
            // 路由传参
            self.$router.push({
                path: "/teacher/questionBank",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
        },

        sortNoUpdate(param) {
            const self = this;
            let url = "/index.php/backquestionbank/updateSortExamPaper";
            let data = {
                id: param.id,
                sort_no: param.sort_no
            };
            // 请求数据
            API.updateSortExamPaper(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
        },
        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() 
        {
            try 
            {
                let self = this;
                let data = {
                    page: self.page,
                    search: self.input
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.getExamPaperList(data)
                    .then((result)=>{
                        if(result.code > 0 )
                        {
                            self.togetherList = result.togetherList;
                            self.tableData = result.examPaperList;
                            // 深拷贝课程列表数据
                            self.tableData2 = JSON.parse(JSON.stringify(self.tableData));
                            self.dataTotal = result.count;
                        }
                        else
                        {
                            // Message.error('系统错误')
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'warning',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            } 
            catch (error) 
            {
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
            }
        }
    }
};
