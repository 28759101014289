<template>
    <!-- 素材列表页 -->
    <div class="questionBank">
    <el-card>
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    icon="el-icon-plus"
                    class="themeButton"
                    @click="handleOpen('is_add_category', '添加试卷')"
                    >新建试卷</el-button
                >
            </div>
            <div class="expSearch">  
                <el-input
                    clearable
                    placeholder="请输入试卷名称"
                    v-model="input"
                    @clear="searchContent">
                    <el-button slot="append" class="el-icon-search" @click="searchContent" ></el-button>
                </el-input>
            </div>
        </div>
        <!-- 表格 -->
        <div class="div">
            <el-table :data="tableData" style="width: 100%" stripe >
                <!-- 试卷名称 -->
                <el-table-column type="index" :index="indexMethod" label="序号" width="100"></el-table-column>
                <el-table-column prop="title" label="试卷名称">
                    <!-- <template slot-scope="scope">
                        <div class="examName" >
                            {{scope.row.title}}
                        </div>
                    </template> -->
                </el-table-column>
                <el-table-column  prop="addtime" sortable label="最后更新时间" width="200"></el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="800">
                    <template slot-scope="scope">
                        <div class="operateItem">
                        <span>
                            <div class="operatorItem floatLeft" @click="handleShare(scope.row)">共享</div>
                            <div class="operatorItem floatLeft" @click="handleOpen('importQuestionBank','导入试卷',scope.$index,scope.row)">导入</div>
                            <div class="operatorItem floatLeft" @click="handleOpen('importQuestionBankAI','智能导入试卷',scope.$index,scope.row)">智能导入</div>
                            <div class="operatorItem floatLeft" @click="exportQuestionBank(scope.row)">导出Excel</div>
                            <div class="operatorItem floatLeft" @click="handlePreview(scope.row)">预览</div>
                            <div class="operatorItem floatLeft" @click="addQuestion(scope.row)" >添加试题</div>
                            <div class="operatorItem floatLeft" @click="editQuestion(scope.row)" >编辑试题</div>
                            <div class="operatorItem floatLeft" @click="handleOpen('is_update_category','编辑试卷',scope.$index,scope.row)">重命名</div>
                            <div class="operatorItem floatLeft" @click="handleOpenStatDialog(scope.row)">统计</div>
                            <div class="operatorDel floatLeft" @click="handleOpen('is_delete_category','删除试卷',scope.$index,scope.row)">删除</div>
                        </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
            <!-- 分页 -->
            <div class="div-height padding-top div">
                <el-pagination
                    @current-change="handleCurrentChange"
                    background
                    layout="prev, pager, next"
                    :current-page.sync="page"
                    :total="dataTotal"
                    v-if="dataTotal != 0"
                >
                </el-pagination>
            </div>
        </el-card>

        <el-dialog
            title="共享试卷"
            :visible.sync="dialogShareVisible"
            width="30%"
        >
            <span>
                <span style="font-size: 0.8333vw;">请选择要共享的同事：</span><br><br>
                <el-select v-model="selectTogether" multiple placeholder="请选择" style="width:100%;">
                    <el-option
                    v-for="item in togetherList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
                v-if="handleSign != 'is_qrcode'">
                <el-button class="themecancelButton" @click="dialogShareVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="dialogShareFix()">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 试题统计弹窗 -->
        <el-dialog
            title="试题统计弹窗"
            :visible.sync="statVisiable"
            width="50%"
            class = "stat-dialog"
            >
            <span class="date">
                <el-date-picker
                    v-model="statDate"
                    type="daterange"
                    format="yyyy-MM-dd" 
                    value-format="yyyy-MM-dd"
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期"
                    class="date">
                </el-date-picker>
            </span>
            <span class="content">统计正确率需要一段时间结果可在试题编辑中查看</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="statVisiable = false">取 消</el-button>
                <el-button type="primary" @click="statHandleOK">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>
                <!-- 修改 -->
                <span
                    v-if="
                        handleSign == 'is_add_category' ||
                            handleSign == 'is_update_category'
                    "
                >
                    <el-input
                        v-model="title"
                        placeholder="请输入试卷名称"
                    ></el-input>
                </span>
                <!-- 删除 -->
                <span v-else-if="handleSign == 'is_delete_category'" class="dialog-span">删除操作无法撤销，您确定要删除吗？</span>
                <!-- 导入 -->
                <span v-else-if="handleSign == 'importQuestionBank'" class="dialog-span">
                    <div class="uploadProjectTip" @click="downloadImportTemplate()">点击下载模板</div>
                    <div>
                        <el-upload
                            class="upload-demo"
                            :action="uploadUrl"
                            :data = {id:exam_paper_id}
                            :on-success="markHandleAvatarSuccess"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :limit="1"
                            :on-exceed="handleExceed"
                            :before-upload="markHandleBeforeUpload"
                            :file-list="fileList">
                            <el-button class="uploadButton">点击上传文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                        </el-upload>
                    </div>
                    
                    <!-- <div class="download-button" @click="downloadImportTemplate()">
                        <i class="el-icon-bottom" ></i>点击下载模板
                    </div><br> -->
                    <!-- <div class="download-title">
                        导入成绩后会覆盖之前的成绩
                    </div><br> -->
                    <!-- <div>
                        <el-upload
                            class="upload-demo"
                            :action="uploadUrl"
                            :data = {id:exam_paper_id}
                            :on-success="markHandleAvatarSuccess"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :limit="1"
                            :on-exceed="handleExceed"
                            :before-upload="markHandleBeforeUpload"
                            :file-list="fileList">
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                        </el-upload>
                    </div> -->
                </span>
                <span v-else-if="handleSign == 'importQuestionBankAI'" class="dialog-span">
                    <div class="uploadProjectTip" @click="downloadAIImportTemplate()">点击下载模板</div>
                    <br>
                    <input type="file" @change="readWordFile" />
                </span>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" >
.el-dialog__body{
    padding: 30px 20px ;
    padding-top: 5px !important;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    padding-bottom: 30px;
    text-align: left;
}
.questionBank .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
.questionBank .el-table{
    font-size: 0.8333vw !important;
    // height: 70vh;
    // overflow: scroll;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
}
.questionBank .el-table__body-wrapper{
    height: 65vh !important;
    overflow: scroll!important;
    scrollbar-width: none!important;
    -ms-overflow-style: none!important;
}
.questionBank .el-table__body-wrapper::-webkit-scrollbar{
    display: none !important
}
.questionBank .expSearch .el-input__inner{
    height: 100% !important;
    line-height: 100% !important;
}
.questionBank .expSearch .el-input{
    height: 100%;
}
.questionBank .el-dialog{
    border-radius: 1vw;
}
.questionBank .el-dialog__body{
    padding: 1.5625vw 1.0417vw ;
    padding-top: 0.2604vw !important;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw;
    // text-align: center;
}
.questionBank .el-table__row{
    // height: 7vh !important;
}
.questionBank .el-table .el-table__cell{
    padding: .5vw 0 !important;
}
</style>
<style lang="scss" scoped>
    @import "@/assets/css/teacher/questionbank/questionBank.scss";
</style>

<script>
    import questionBank from "@/assets/js/teacher/questionbank/questionBank.js";
    export default {
        ...questionBank
    };
</script>
